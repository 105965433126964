module.exports = {
  placeholder_email: "你的电邮",
  placeholder_subject: "联系主题",
  placeholder_hotel: "酒店名",
  placeholder_selected_hotel: "选择酒店",
  placeholder_message: "联系信息",
  btn_label: "发出反馈",
  submit_success: "反馈信息已成功提交。",
  submit_error:
    "我们在读取您数据的信息时遇到错误。请稍后重试，或通过 info@ayanarewards.com 联系我们.",
};

module.exports = {
  title_info:
    "You can also print out this form and send us via fax: (62-21) 251 1777",
  label_confirmation_no: "确认编号",
  label_guest_name: "客户姓名",
  label_list_hotel: "饭店名称",
  placeholder_hotel_selected: "选择酒店",
  label_check_in_date: "入住日期",
  label_check_out_date: "离店",
  label_file_upload: "附加",
  label_comment: "备注",
  btn_label: "发出",
  submit_success: "关于丢失积分的问题已成功提交。",
  submit_error:
    "我们在读取您数据的信息时遇到错误。请稍后重试，或通过 info@ayanarewards.com 联系我们.",
};

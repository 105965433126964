module.exports = {

	'label_edit_profile': '编辑我的信息',
	'label_id_card': 'ID 卡',
	'label_no_password': '密码',
	'label_title': '称谓',
	'label_gender': '性别',
	'label_marital_status': '婚姻状况',
	'label_email_address': '邮件',
	'label_nationality': '国籍',
	'label_birthday': '生日',
	'label_language': '用户语言',
	'label_member_referal': '会员推介',
	'label_company': '公司',
	'label_mobile_phone': '移动电话',
	'label_home_phone': '家庭电话',
	'label_office_phone': '办公电话',
	'label_fax_number': '传真',
	'label_address': '地址',
	'label_state': '洲/省',
	'label_postal_code': '邮政编码',
	'label_city': '居住国家',
	'label_join_date': '加入日期',
	'label_expiry_date': '到期日期',
	'label_edit_information': '编辑我的信息'
}
module.exports = {
    'button_upcoming': '待出行',
    'button_history': '历史',
    'title_confirmation_no': '确认号',
    'title_booking_date': '预订日期',
    'title_arrival': '抵达',
    'title_departure': '离开',
    'title_hotel_name': '酒店',
    'title_status': '状态',
    'reservation_footnote': '可能需要2天以更新您的预定信息',
}
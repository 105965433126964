module.exports = {
    'title_processed_date': '处理日期',
    'title_description': '说明',
    'title_place_of_visit': '访问场所',
    'title_transaction_reference': '交易参考',
    'title_transaction_type': '交易类型',
    'title_earned_point': '已获取积分',
    'title_bonus_point': '奖励积分',
    'title_total_point': '积分总计',
    'title_total_expiry': '积分期限'
}
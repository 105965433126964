module.exports = {

	'title_welcome': '欢迎光临, ',
	'information': '我们想让您的阿雅娜体验与众不同。作为我们的会员，您将享受任何阿雅娜酒店的免费住宿，房型升级，会员专享优惠以及欢迎礼品。',
	'member_name_label': '会员姓名',
	'membership_no': '会员编号',
	'label_membership': '会员资格 ',
	'membership_gold_label': 'Gold',
	'membership_silver_label': 'Silver',
	'membership_platinum_label': 'Platinum',
	'point_label': '会员点数',
	'menu_tab_profile': '会员页面',
	'menu_tab_my_redeem': '点数兑换',
	'menu_tab_password_change': '修改密码',
	'menu_tab_missing_point': '无效点数',
	'menu_tab_reward_history': '兑换历史',
	'menu_tab_future_booking': '未来的预订',
	'menu_tab_feedback': '反馈',
	'menu_tab_my_voucher': '我的优惠券',
	'menu_tab_transaction_history': '积分记录',
	'menu_tab_my_reservation': '我的预订',
	'label_your_point': '您的积分',
	'label_expired_on': '有效期限',
	'label_required_nex_point': '还需 %pts% 积分'
}